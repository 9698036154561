import DriversProfile from '../../components/Carriers/Drivers/DriversProfile'

export default {
    methods: {
        UIDrivers_OpenProfile(driverId) {
            let modal = this.$ShowModal({
                title: `Driver's Profile`,
                component: DriversProfile,
                props: {
                    driverId
                },
                hideCancelButton: true
            });

            modal.onConfirm(() => {
                modal.close();
            })
        }
    },
}