<template>
    <div class="load-board-stats">


        <div class="item-group ">
            <div class="item-title">Gross Income</div>
            <div class="item-value">${{ CONVERT_PRICE(gross) }}</div>
        </div>


        <div class="item-group">
            <div class="item-title">Loads count</div>
            <div class="item-value">{{ numberOfLoads }} <span class="grayed-out">{{ numberOfLoads === 1 ? 'load' : 'loads' }}</span></div>
        </div>


        <div class="item-group">
            <div class="item-title">Dispatch Leader</div>
            <div class="item-value">
                <div class="profile" v-if="dispatchLeader">
                    <div class="displayName">{{ dispatchLeader.user.displayName }}</div>
                    <div class="profile-text">booked {{ dispatchLeader.count }} {{ dispatchLeader.count === 1 ? 'load' : 'loads' }}</div>
                </div>
                <div v-else>No data</div>    
            </div>
        </div>


        <div class="item-group">
            <div class="item-title">Gross Leader</div>
            <div class="item-value">
                <div class="profile" v-if="grossLeader">
                    <div class="displayName">{{ grossLeader.user.displayName }}</div>
                    <div class="profile-text">made ${{ CONVERT_PRICE(grossLeader.gross) }} in gross</div>
                </div>
                <div v-else>No data</div>  
            </div>
        </div>

<!-- 
        <StatsItem icon="fa-solid fa-dollar-sign" text="Gross income" :loading="isLoading(['GetLoadsStats'])">
            <span>${{ CONVERT_PRICE(gross) }}</span>
        </StatsItem>

        <StatsItem icon="fa-solid fa-boxes-stacked" text="Number of loads" :loading="isLoading(['GetLoadsStats'])">
            <span>{{ numberOfLoads }}</span>
        </StatsItem>

        <StatsItem icon="fa-solid fa-file-signature" text="Dispatch leader" :loading="isLoading(['GetLoadsStats'])">
            <div class="profile" v-if="dispatchLeader">
                <div class="displayName">{{ dispatchLeader.user.displayName }}</div>
                <div class="profile-text">booked {{ dispatchLeader.count }} {{ dispatchLeader.count === 1 ? 'load' : 'loads' }}</div>
            </div>
            <div v-else>No data</div>
        </StatsItem>

        <StatsItem icon="fa-solid fa-trophy" text="Gross leader" :loading="isLoading(['GetLoadsStats'])">
            <div class="profile" v-if="grossLeader">
                <div class="displayName">{{ grossLeader.user.displayName }}</div>
                <div class="profile-text">made ${{ CONVERT_PRICE(grossLeader.gross) }} in gross</div>
            </div>
            <div v-else>No data</div>
        </StatsItem> -->

    </div>
</template>

<script>
import StatsItem from '../../StatsItem.vue'
    export default {
        components: {
            StatsItem
        },
        props: ['stats'],
        computed: {
            gross() {
                if(this.stats && this.stats.gross) return this.stats.gross;
                return 0;
            },
            numberOfLoads() {
                if(this.stats && this.stats.count) return this.stats.count;
                return 0;
            },
            dispatchLeader() {
                if(this.stats && this.stats.users && this.stats.users.dispatchLeader) return this.stats.users.dispatchLeader;
                return null;
            },
            grossLeader() {
                if(this.stats && this.stats.users && this.stats.users.grossLeader) return this.stats.users.grossLeader;
                return null;
            }
        }
    }
</script>

<style lang="scss" scoped>

.item-group {
    background: $themeColor1;
    padding: 15px;
    display: grid;
    gap: 10px;
    box-shadow: $boxShadow;
    border-radius: 9px;
    transition: ease 0.3s;
    .grayed-out {
        font-size: 16px;
        color: $textLight;
    }
    .item-title {
        text-transform: uppercase;
        color: $textLight;
    }
    .item-value {
        font-size: 24px;
        font-weight: 300;
    }
    &:hover {
        transform: scale(1.01);
    }
    &.active {
        background: $themeColor2;
        .item-title {
            color: rgba(255,255,255,0.8)
        }
        .item-value {
            color: $themeColor1;
            font-size: 32px;
        }
    }
}

.vl {
    height: 100%;
    width: 1px;
    background: $borderColor;
}

.load-board-stats {
    display: grid;
    // grid-template-columns: minmax(0, 1fr) 1px minmax(0, 1fr) 1px minmax(0, 1fr) 1px minmax(0, 1fr);
    grid-template-columns: repeat(4, minmax(0, 1fr));
    column-gap: 20px;
    row-gap: 30px;
}

.stats-block {
    background: $bg;
    border: 0;
    box-shadow: none;
    transition: ease 0.3s;
    &:hover {
        background: $themeColor1;
        box-shadow: $boxShadow;
    }
}

.profile {
    .displayName {
        font-size: 26px;
    }
    .profile-text {
        font-size: 12px;
        color: $themeColor2;
        font-weight: 400;
    }
}

</style>