<template>
    <div>
        <AppMap height="75%" ref="AppMap">
            <AppMapMarker
                :latLng="[location.latitude, location.longitude]"
                :type="'STOP_MARKER'"
                v-for="location of locations"
                :key="location._id"
                :zIndexOffset="0"
            >
                <div class="locationMarkerContent">
                    <div class="locationDisplayName">{{ location.displayName }}</div>
                    <div class="footer">
                        <a href="" @click.prevent class="date" v-tooltip="{ content: convertDate(location.date) }">{{ locationTimeAgo(location.date) }}</a>
                        <div class="coordinates">{{ location.latitude.toFixed(4) }}, {{ location.longitude.toFixed(4) }}</div>
                    </div>
                </div> 
            </AppMapMarker>

            <AppMapMarker :latLng="[currentLocationMarker.latitude, currentLocationMarker.longitude]" :type="'CURRENT_LOCATION_MARKER'" :zIndexOffset="100" v-if="currentLocationMarker">
                <div class="locationMarkerContent">
                    <div><strong>Last Known Location</strong></div>
                    <div class="locationDisplayName">{{ currentLocationMarker.displayName }}</div>
                    <div class="footer">
                        <a href="" @click.prevent class="date" v-tooltip="{ content: convertDate(currentLocationMarker.date) }">{{ locationTimeAgo(currentLocationMarker.date) }}</a>
                        <div class="coordinates">{{ currentLocationMarker.latitude.toFixed(4) }}, {{ currentLocationMarker.longitude.toFixed(4) }}</div>
                    </div>
                </div> 
            </AppMapMarker>

            <LPolyline :lat-lngs="polylineLocations" color="rgba(0, 122, 255, 0.60)" dashArray="0" :weight="2"/>
            <!-- <LCircle v-for="(circle, key) of geofences" :lat-lng="circle.latLng" :radius="circle.radius"/> -->
        </AppMap>
    </div>
</template>

<script>

import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LPopup, LCircle, LPolyline } from "@vue-leaflet/vue-leaflet";

import DriversJS from '../../../mixins/Drivers/index'
import moment from "moment";

import TimeAgo from 'javascript-time-ago'
const timeAgo = new TimeAgo('en-US')

    export default {
        mixins: [DriversJS],
        props: ['modal', 'location', 'driverId'],
        components: {
            LMap,
            LTileLayer,
            LMarker,
            LPopup,
            LCircle,
            LPolyline
        },
        data() {
            return {
                zoom: 10,
                center: [39.781156478647155, -101.40170000039568],
                userLocation: null,
                _map: null,
                geofences: [],
                locations: []
            }
        },
        watch: {
            userLocation(ev) {
                this.scrollToMarker();
            },
            locations: {
                deep: true,
                handler() {
                    this.getGeofences();
                }
            }
        },
        methods: {
            convertDate(date) {
                return moment(date).format("HH:mm DD/MM/YYYY")
            },
            locationTimeAgo(date) {
                return timeAgo.format(moment(date).toDate());
            },
            mapReady(ev) {
                this._map = ev;
                this.scrollToMarker();
            },
            scrollToMarker() {
                if(this._map) this._map.setView([this.currentLocationMarker.latitude, this.currentLocationMarker.longitude], 10)
            },
            getGeofences() {

                try {

                    const lat = this.locations[0].latitude;
                    const lon = this.locations[0].longitude;
                    const circles = [];


                    let count = 6;
                    var radius = 375;
                    var angle = 2 * Math.PI / count;
                    for (var i = 0; i < count; i++) {
                        var x = lat + (radius / 111111) * Math.cos(i * angle);
                        var y = lon + (radius / 111111) * Math.sin(i * angle);
                        circles.push({ latLng: [x, y], radius: 250 })
                    }

                    let count2 = 6;
                    let radius2 = 1500;
                    let angle2 = 2 * Math.PI / count2;
                    for (var i = 0; i < count2; i++) {
                        var x = lat + (radius2 / 111111) * Math.cos(i * angle2);
                        var y = lon + (radius2 / 111111) * Math.sin(i * angle2);
                        circles.push({ latLng: [x, y], radius: 1000 })
                    }


                    let count3 = 6;
                    let radius3 = 575;
                    let angle3 = 2 * Math.PI / count3;
                    for (var i = 0; i < count3; i++) {
                        var x = lat + (radius3 / 111111) * Math.cos(i * angle3);
                        var y = lon + (radius3 / 111111) * Math.sin(i * angle3);
                        circles.push({ latLng: [x, y], radius: 325 })
                    }

                   

                    // circles.push({ latLng: [lat, lon], radius: 2500 });  


                    this.geofences = circles;
                } catch(e) {
                    this.geofences = [];
                }
            },
            getDriversLocations() {
                this.drivers_get_last_known_locations(this.driverId, (err, body) => {
                    if(!err) {
                        this.locations = body;
                        if(this.locations[0]) {
                            this.$refs['AppMap'].setView([this.locations[0].latitude, this.locations[0].longitude], 20);
                            // this.$refs['AppMap'].panTo([this.locations[0].latitude, this.locations[0].longitude]);
                        }
                    }
                });
            }
        },
        computed: {
            currentLocationMarker() {
                if(!this.locations[0]) return null;
                return this.locations[0];
            },
            polylineLocations() {
                return this.locations.map(item => {
                    return [item.latitude, item.longitude]
                });
            }
        },
        mounted() {
            this.getGeofences();
            this.getDriversLocations();
            this.$WebSocket.on('/dispatch/carriers/drivers/LocationUpdate', 'DriversLocationMapComponent', (data) => {
                if(this.driverId === data.driver) {
                    this.locations.unshift(data);
                    this.$refs['AppMap'].panTo([this.locations[0].latitude, this.locations[0].longitude]);
                }
            });
        }
    }
</script>

<style lang="scss" scoped>
.mapHolder {
    width: 100%;
    padding-top: 75%;
    position: relative;
    .map {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.locationMarkerContent {
    display: grid;
    gap: 7px;
    .date {
        font-size: 12px;
        color: $textLight;
    }
    .coordinates {
        font-size: 12px;
        color: $themeColor2;
    }
    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
</style>