<template>
    <div class="load-item" :style="`grid-template-columns: ${size.join(' ')}`">
        <div class="grid" style="gap: 3px;">
            <div class="text-overflow">{{ date }}</div>
            <div class="text-overflow">#{{ item.loadId }}</div>
        </div>
        
        <div class="vl"></div>
        <div class="text-overflow">{{ item.brokerLoadNumber }}</div>
        <div class="vl"></div>
        <div>
            <div class="text-overflow rate">${{ CONVERT_PRICE(Number(item.rate).toFixed(0)) }}</div>
            <div class="text-overflow rpm">${{ CONVERT_PRICE(Number(item.rpm).toFixed(2)) }}*/mi</div>
        </div>
        <div class="vl"></div>      
        <!-- origin & destination -->
        <div class="text-overflow">

            <div class="stops-list">
                <div class="stop-item pick">
                    <div class="stop-type">{{ item.origin }}</div>
                </div>
                <div class="stop-item">
                    <div class="stop-type">{{ item.destination }}</div>
                </div>
            </div>

        </div>
        <div class="vl"></div>
        <div>
            <div class="text-overflow"><span class="strong">{{ CONVERT_PRICE(item.weight) }}</span> lbs</div>
            <div class="text-overflow"><span class="strong">{{ item.length }}</span> ft</div>
        </div>
        <div class="vl"></div>
        <!-- carrier -->
        <div>
            <div class="text-overflow">{{ item.carrier.companyName }}</div>
            <div class="text-overflow"><a href="" @click.prevent="openDriversProfile">{{ item.driver.name }} {{ item.driver.lastname }}</a></div>
        </div>
        <div class="vl"></div>
        <div>
            <div class="text-overflow">{{ item.broker.name }}</div>
            <div class="text-overflow" v-if="item.brokerContactPhone"><a :href="`tel:${item.brokerContactPhone}`" @click.prevent>{{ item.brokerContactPhone }}</a></div>
        </div>
        <div class="vl"></div>
        <div class="text-overflow">
            <div class="profile">
                <div class="image" :style="[`background-image: url('${userImage}')`]"></div>
                <div class="displayName"><a href="" @click.prevent>{{ userName }}</a></div>
            </div>
        </div>
        <div class="vl"></div>
        <div>
            <DropDownList class="load-status" :disabled="!isAllowedToEdit" :class="[item.status]" @select="statusChange" v-model="item.status" position="right" :options="LOAD_STATUS_LIST" selectAttribute="value" showAttribute="name"/>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import UI_Profile from '../../../mixins/Drivers/UI_Profile';

    export default {
        mixins: [UI_Profile],
        props: ['item', 'size'],
        emits: ['statusChange'],
        methods: {
            statusChange(status) {
                this.$emit('statusChange', status.value);
            },
            openDriversProfile() {
                this.UIDrivers_OpenProfile(this.item.driver._id);
            }
        },
        computed: {
            ...mapGetters(['user']),
            date() {
                return moment.utc(this.item.date).format("ddd D MMM");
                // return moment.utc(this.item.date).format("D/MM");
            },
            userImage() {
                return this.item.user.image ? this.item.user.image : this.DEFAULTS.USER.IMAGE;
            },
            userName() {
                return this.item.user.displayName ? this.item.user.displayName : this.item.user.name + ' ' + this.item.user.lastname;
            },
            status() {
                return this.item.removed ? { value: 'Removed', class: 'removed' } : this.item.pending ? { value: 'Invited', class: 'pending' } : { value: 'Active', class: 'active' };
            },
            isAllowedToEdit() {
                if(this.item.user._id === this.user._id) return true;
                return this.user.isAuthorized('Loads', 'Edit all');
            }
        }
    }
</script>

<style lang="scss" scoped>

.vl {
    height: 100%;
    background: $borderColor;
}

.load-item {
    display: grid;
    padding: 10px;
    gap: 10px;
    border-radius: 9px;
    position: relative;
    transition: ease 0.3s;
    cursor: pointer;
    &::before {
        content: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 100%;
        background: $themeColor2;
    }
    &:nth-child(2n) {
        background: rgba(0,0,0,0.02);
    }
    &:hover {
        background: rgba(0,0,0,0.04);
        &::before {
            // content: ''
        }
    }
}


.stops-list {
    .stop-item {
        color: $textShade;
        padding: 2px 0 2px 16px;
        position: relative;
        display: grid;
        row-gap: 2px;
        &::before {
            content: '';
            position: absolute;
            top: 2px;
            left: 0;
            width: 5px;
            height: 6px;
            background: $themeColor2;
            border: 1px solid $themeColor2;
            box-sizing: border-box;
            transform: translate(0px, 5px);
        }
        &::after {
            content: '';
            position: absolute;
            top: 4px;
            left: 0;
            bottom: 6px;
            width: 1px;
            background: $themeColor2;
            transform: translate(2px, 11px);
        }
        &:last-child {
            &::after {
                content: none;
            }
        }
        &.pick {
            &::before {
                background: transparent;
            }
        }
        .stop-type {
            display: flex;
            align-items: center;
            font-size: 14px;
            height: 16px;
        }
    }
}

.rpm {
    color: rgba(0,0,0,0.45);
    font-size: 12px;
}

.rate {
    font-size: 15px;
}

.profile {
    display: grid;
    align-items: center;
    grid-template-columns: 34px minmax(0, auto);
    column-gap: 15px;
    .image {
        width: 100%;
        padding-top: 100%;
        background-size: cover;
        background-position: center;
        border-radius: 9px;
    }
}

.strong {
    font-weight: 600;
}

.load-status {
    height: 28px;
    background: #ccc;
    padding: 0 10px;
    border-radius: 14px;
    transition: ease 0.3s;
    &:after {
        content: none !important;
    }
    &.pending {
        border: 1px solid $borderColor;
        background: $themeColor1;
        color: $textShade;
    }
    &.atpickup {
        border: 1px solid $themeColor2;
        background: $themeColor1;
        color: $themeColor2;
    }
    &.pickedup {
        border: 1px solid $themeColor2;
        background: $themeColor2;
        color: #fff;
    }
    &.delayed {
        border: 1px solid $warning;
        background: $warning;
        color: #000;
    }
    &.atdelivery {
        border: 1px solid $success;
        background: $themeColor1;
        color: $success;
    }
    &.delivered {
        border: 1px solid $success;
        background: $success;
        color: #fff;
    }
    &.tonu {
        border: 1px solid $borderColor;
        background: $borderColor;
        color: $text;
    }
    &.canceled {
        border: 1px solid $error;
        background: $error;
        color: #fff;
    }
}

.user-status {
    padding: 0 10px;
    height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 11px;
    font-size: 10px;
    &.active {
        color: #fff;
        background: $success;
    }
    &.removed {
        background: $error;
        color: #fff;
    }
    &.pending {
        background: $themeColor2;
        color: #fff;
    }
}
</style>