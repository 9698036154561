<template>
    <div class="modal-wrapper">
        <div v-if="driver" class="driver-wrapper">

            <div class="image" :style="[`background-image: url('${driverImage}')`]"></div>
            <div class="name-group">
                <div class="name">{{ driver.name }} {{ driver.lastname }}</div>
                <div class="carrier" v-if="driver.carrier">{{ driver.carrier.companyName }}</div>
            </div>

            <div class="action">
                <Button text="Send message" size="small" @click="sendMessage" theme="white"/>
            </div>

            <div v-if="driver.lastKnownLocation" class="lastKnownLocation">
                <a href="" @click.prevent="UIDrivers_Location(driver._id, driver.lastKnownLocation)" class="location-address">{{ driver.lastKnownLocation.displayName }}</a>
                <div class="date" v-tooltip="{ content: locationDate }">{{ locationTimeAgo }}</div>
            </div>

            <div class="stats">
                <div class="stats-item">
                    <div class="title">Gross</div>
                    <div class="value">${{ driver.stats.gross }}</div>
                </div>

                <div class="vl"></div>

                <div class="stats-item">
                    <div class="title">Number of loads</div>
                    <div class="value">{{ driver.stats.loads }}</div>
                </div>

                <div class="vl"></div>

                <div class="stats-item">
                    <div class="title">Miles driven</div>
                    <div class="value">{{ driver.stats.miles }}</div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import moment from 'moment';
import Drivers from '../../../mixins/Drivers';

import UI_Location from '../../../mixins/Drivers/UI_Location'

import TimeAgo from 'javascript-time-ago'
const timeAgo = new TimeAgo('en-US')


    export default {
        mixins: [Drivers, UI_Location],
        props: ['driverId', 'modal'],
        data() {
            return {
                driver: null
            }
        },
        computed: {
            driverImage() {
                if(this.driver.image) return this.driver.image;
                return this.DEFAULTS.USER.IMAGE;
            },
            locationTimeAgo() {
                let date = moment(this.driver.lastKnownLocation.date)
                return timeAgo.format(date.toDate());
            },
            locationDate() {
                let date = moment(this.driver.lastKnownLocation.date)
                return date.format("HH:mm, D MMM, YYYY");
            }
        },
        methods: {
            getDriversDetails() {
                if(!this.driverId) this.modal.close();
                this.drivers_get_details(this.driverId, (err, body) => {
                    if(!err) {
                        this.driver = body;
                    } else {
                        this.modal.close()
                    }
                });
            },
            sendMessage() {
                this.$Chat.selectChat(this.driverId);
            }
        },
        mounted() {
            this.getDriversDetails();
        }
    }
</script>

<style lang="scss" scoped>
.modal-wrapper {
    padding: 20px;
}

.driver-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.lastKnownLocation {
    align-self: center;
    display: flex;
    flex-direction: column;
    .date {
        text-align: center;
        font-size: 12px;
        color: $textLight;
        align-self: center;
        cursor: pointer;
    }
    .location-address {
        text-align: center;
    }
}

.image {
    width: 120px;
    height: 120px;
    background-color: #ccc;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    align-self: center;
}

.action {
    align-self: center;
}

.name-group {
    display: grid;
    gap: 5px;
    .name {
        text-align: center;
        font-weight: 600;
        font-size: 18px;
    }
    .carrier {
        text-align: center;
        font-size: 14px;
    }
}

.stats {
    width: 100%;
    display: flex;
    border: 1px solid $borderColor;
    background: $themeColor1;
    box-shadow: $boxShadow;
    padding: 20px 10px;
    border-radius: 20px;
    align-items: center;
    .vl {
        width: 1px;
        height: 40px;
        background: $borderColor;
    }
    .stats-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 7px;
        .title {
            text-transform: uppercase;
            font-size: 11px;
            color: rgba(0,0,0,0.5);
            text-align: center;
        }
        .value {
            text-align: center;
            font-size: 24px;
            font-weight: 500;
        }
    }
}
</style>