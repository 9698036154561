import DriversLocation from '../../components/Carriers/Drivers/DriversLocation'

export default {
    methods: {
        UIDrivers_Location(driverId, location = null) {
            let modal = this.$ShowModal({
                title: `Driver's Location`,
                component: DriversLocation,
                width: '792px',
                props: {
                    driverId,
                    location
                },
                hideCancelButton: true
            });

            modal.onConfirm(() => {
                modal.close();
            })
        }
    },
}