<template>
    <div class="grid gap-20">

        <div class="wrapper grid row-20">

            <ActionBar title="Dispatch">
                    <div style="display: flex; width: 100%; justify-content: space-between; align-items: center;">
                        <Button text="Add Load" @click="addLoad" size="small"/>

                        <div class="page-filters">
                            <div class="filter-item">
                                <div class="filter-item-name">Date</div>
                                <DropDownDateRangePicker v-model="_pageManager.query.date" position="center" placeholder="All time" mode="date"/>
                            </div>

                            <div class="filter-item">
                                <div class="filter-item-name">Dispatchers</div>
                                <DropDownCheckbox v-model="_pageManager.query.user" position="center" placeholder="All" selectAttribute="_id" showAttribute="displayName" :options="dispatchers"/>
                            </div>

                            <div class="filter-item">
                                <div class="filter-item-name">Carrier</div>
                                <DropDownCheckbox v-model="_pageManager.query.carrier" position="center" placeholder="All" selectAttribute="_id" showAttribute="companyName" :options="carriers"/>
                            </div>

                            <div class="filter-item">
                                <div class="filter-item-name">Status</div>
                                <DropDownList v-model="_pageManager.query.status" position="right" placeholder="All" :options="statusFilter" selectAttribute="value" showAttribute="name"/>
                            </div>
                        </div>
                    </div>

                </ActionBar>

            <div class="grid row-20">

                <LoadBoardStats :stats="stats"/>


                <PageSearchInput v-model="_pageManager.query.search" placeholder="Search loads by load #, pro #, origin or destination"/>

                <div class="item-list">
                    <TableView :head="tableHead" :size="columnsSizes" v-if="(_pageManager.list.length > 0)" :loading="isLoading(['GetLoads'])">
                        <TransitionGroup name="list">
                            <LoadItemV2
                                :size="columnsSizes"
                                :key="item._id" 
                                @dblclick="loads_view(item._id)"
                                v-for="(item, key) of _pageManager.list"
                                :item="item" @statusChange="(status) => { changeLoadStatus(item._id, status) }"
                            />
                        </TransitionGroup>
                    </TableView>

                    <div v-else>
                        <div v-if="isLoading(['GetLoads'])" class="spinner-flex"><Spinner/></div>
                        <div v-else><NoDataMessage text="Nothing found"/></div>
                    </div>
                </div>

                <div class="pagination">
                    <Pagination :manager="_pageManager" @pageChange="(page) => { _pageManager.query.page = page }"/>
                </div>
                
            </div>
            
        </div>
    </div>
</template>

<script>
import Pagination from '../../mixins/Pagination/components/Pagination.vue'
import LoadItemV2 from '../../components/Dispatch/Loads/LoadItemV2.vue'
import LoadBoardStats from '../../components/Dispatch/Loads/LoadBoardStats.vue'
import AddLoad from '../../components/Dispatch/Loads/AddLoad.vue'

import moment from 'moment'

import LoadsJS from '../../mixins/Loads'
import PaginationJS from '../../mixins/Pagination'

    export default {
        mixins: [PaginationJS, LoadsJS],
        components: {
            Pagination,
            LoadItemV2,
            LoadBoardStats
        },
        data() {
            return {
                tableHead: [
                    { name: 'Date' },
                    { name: '' },



                    { name: 'Pro #' },
                    { name: '' },

                    { name: '' },
                    { name: '' },

                    { name: '' },
                    { name: '' },

                    { name: '' },
                    { name: '' },

                    { name: 'Carrier' },
                    { name: '' },

                    { name: 'Broker' },
                    { name: '' },

                    { name: 'Dispatcher' },
                    { name: '' },

                    { name: 'Status' },
                ],
                columnsSizes: [ 
                    'minmax(50px, 75px)',
                    '1px',
                    'minmax(50px, 75px)',
                    '1px',
                    '75px',
                    '1px',
                    'minmax(50px, 175px)',
                    '1px',
                    '75px',
                    '1px',
                    'minmax(50px, 1fr)', // carrier
                    '1px',
                    'minmax(50px, 1fr)', // broker
                    '1px',
                    'minmax(100px, 150px)',
                    '1px',
                    '140px',
                ],
                carriers: [],
                dispatchers: [],
                stats: null
            }
        },
        computed: {
            statusFilter() {
                let array = [{ value: null, name: 'All' }];
                array = array.concat(this.LOAD_STATUS_LIST);
                return array;
            }
        },
        watch: {
            "_pageManager.query.search": {
                deep: true,
                handler() {
                    this._pageManager.query.page = 1;
                }
            },
            "_pageManager.query": {
                deep: true,
                handler() {
                    this.getLoads();
                }
            }
        },
        methods: {
            changeLoadStatus(loadId, status) {
                this.ajax('GetListOfCarriers', {
                    url: `/dispatch/loads/${loadId}/status/${status}`,
                    method: 'PUT',
                }, (err, body) => {
                    if(err) {
                        this.$ShowAlert(body.message || this.TEXT_ERRORS['SOMETHING_WRONG']);
                    }
                });
            },
            addLoad() {
                this.$ShowModal({
                    title: 'Add Load',
                    description: 'Enter all required information about the load',
                    component: AddLoad,
                });
            },
            getListOfCarriers() {
                this.ajax('GetListOfCarriers', {
                    url: '/dispatch/carriers/all',
                    method: 'GET',
                }, (err, body) => {
                    if(!err) this.carriers = body;
                });
            },
            getListOfDispatchers() {
                this.ajax('GetListOfDispatchers', {
                    url: '/users/permissions?name=Loads',
                    method: 'GET',
                }, (err, body) => {
                    if(!err) this.dispatchers = body;
                });
            },
            getLoads() {
                let fullQuery = JSON.parse(JSON.stringify(this._pageManager.query));
                if(fullQuery.date) {
                    fullQuery.start_date = fullQuery.date.start;
                    fullQuery.end_date = fullQuery.date.end;
                    delete fullQuery.date;
                }
                this.getLoadStats();
                let query = new URLSearchParams(fullQuery).toString();

                this.ajax('GetLoads', {
                    url: '/dispatch/loads?' + query,
                    method: 'GET',
                }, (err, body) => {
                    if(!err) {
                        this._pageManager.pages = body.pages;
                        this._pageManager.count = body.count;
                        this._pageManager.list = body.list;
                        this._pageManager.page = body.page;
                        this._pageManager.hasNextPage = body.hasNextPage;
                        this._pageManager.hasPrevPage = body.hasPrevPage;
                    }
                });
            },
            getLoadStats(stopSlear = false) {
                if(!stopSlear) this.stats = null;
                let fullQuery = JSON.parse(JSON.stringify(this._pageManager.query));
                if(fullQuery.date) {
                    fullQuery.start_date = fullQuery.date.start;
                    fullQuery.end_date = fullQuery.date.end;
                    delete fullQuery.date;
                }

                let query = new URLSearchParams(fullQuery).toString();

                this.ajax('GetLoadsStats', {
                    url: '/dispatch/loads/stats?' + query,
                    method: 'GET',
                }, (err, body) => {
                    if(!err) {
                        this.stats = body;
                    }
                });
            },
            isLoadMatchingSearchQuery(body) {
                let bodyDate = moment.utc(body.date);
                let start = moment.utc(this._pageManager.query.date.start);
                let end = moment.utc(this._pageManager.query.date.end);
                if(bodyDate.diff(start) < 0 || bodyDate.diff(end) > 0) return false;

                let dispatchers = this._pageManager.query.user;
                if(dispatchers) {
                    if(Array.isArray(dispatchers) && dispatchers.length > 0 && !dispatchers.includes(body.user._id)) return false;
                }

                let carriers = this._pageManager.query.carrier;
                if(carriers) {
                    if(Array.isArray(carriers) && carriers.length > 0 && !carriers.includes(body.carrier._id)) return false;
                }

                let status = this._pageManager.query.status;
                if(status && status !== body.status) return false; 

                return true;
            },
            onLoadUpdate(body) {
                let i = 0;
                let updated = false;
                for(const load of this._pageManager.list) {
                    if(load._id === body._id) {
                        this._pageManager.list[i] = body;
                        updated = true;
                        break;
                    }
                    i++;
                }
                if(updated) {
                    this.checkIfUpdatedLoadsMatchingQuery();
                } else {
                    if(this.isLoadMatchingSearchQuery(body)) {
                        this._pageManager.list.push(body);
                        this.sortLoadsList();
                    }
                }
                this.getLoadStats(true);
            },
            onLoadInsert(body) {
                let isMatching = this.isLoadMatchingSearchQuery(body);
                if(isMatching) {
                    this._pageManager.list.push(body);
                    this.sortLoadsList();
                    this.getLoadStats(true);
                }
            },
            checkIfUpdatedLoadsMatchingQuery() {
                
                let newList = this._pageManager.list.filter(item => {
                    let isMatching = this.isLoadMatchingSearchQuery(item);
                    if(isMatching) return true;
                    return false;;
                });
                
                this._pageManager.list = newList;

            },
            sortLoadsList() {
                this._pageManager.list = this._pageManager.list.sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0));
            }
        },
        mounted() {
            let date = moment.utc().startOf('isoWeek');
            this._pageManager.query.date = {
                start: date.clone().toDate(),
                end: date.clone().endOf('isoWeek').toDate(),
            }
            this.getListOfCarriers();
            this.getListOfDispatchers();
            this.getLoads();
            this.$WebSocket.on('/dispatch/loads/LoadUpdate', 'dispatch-loads', this.onLoadUpdate);
            this.$WebSocket.on('/dispatch/loads/LoadInsert', 'dispatch-loads', this.onLoadInsert);
        }
    }
</script>

<style lang="scss" scoped>

.wrapper {
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
}

.header-group {
    // background: $themeColor1;
    border-bottom: 1px solid $borderColor;
    .wrapper {
        padding: 10px 20px;
    }
}

.item-list {
    border-bottom: 1px solid $borderColor;
    padding: 0 0 20px 0;
}

.page-filters {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
}


.list-move, .list-enter-active, .list-leave-active {
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.list-enter-from, .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
    position: absolute;
}
</style>