<template>
    <div class="add-load-wrapper grid row-20">

        <div class="grid row-15">
            <h3>Carrier details</h3>
            <InputDropDownList :error="errors.carrier" name="Carrier" v-model="item.carrier" placeholder="Select carrier" selectAttribute="_id" showAttribute="companyName" :options="carriers"/>
            
            <div class="grid cols-2 gap-15" v-if="item.carrier">
                <InputDropDownList :error="errors.carrierChargeType" name="Type" position="center" v-model="item.carrierChargeType" placeholder="Select type" selectAttribute="_id" showAttribute="name" :options="chargeTypes"/>
                <InputDropDownList :error="errors.driver" name="Driver" position="center" v-model="item.driver" placeholder="Select driver" selectAttribute="_id" :showAttribute="(driver) => { return driver.name + ' ' + driver.lastname }" :options="drivers"/>
            </div>

            <CitySearch v-model="item.emptyCity" name="Empty City" placeholder="Enter empty city"/>

        </div>


        <div class="grid row-15">
            <h3>Load details</h3>


            <div class="input">
                <div class="_header">
                    <label class="label">Load stops</label>
                    <span class="_error">{{ errors.stops }}</span>
                </div>
                <div class="grid gap-15">
                    <div class="stops-list" v-if="item.stops">
                        <div class="stop-item" :class="[stop.type]" :key="key" v-for="(stop, key) of item.stops">
                            <div class="stop-type">{{ stop.type }} {{ stop.typeKey }}</div>
                            <div class="stop-city">{{ stop.place.cityState }}</div>
                        </div>
                    </div>
                    <div>
                        <a href="" @click.prevent="selectLoadStops">{{ item.stops ? 'Edit stops' : 'Select stops' }}</a>
                    </div>
                </div>
            </div>

            <Input name="Rate" v-model="item.rate" :error="errors.rate" placeholder="Enter load rate"/>
            <div class="grid cols-2 gap-15">
                <Input name="Weight" v-model="item.weight" :error="errors.weight" placeholder="Enter load weight"/>
                <Input name="Length" v-model="item.length" :error="errors.length" placeholder="Enter load length"/>
            </div>
            <InputFileStorageUploader name="Rate confirmation" v-model="item.rateConfirmation"  :error="errors.rateConfirmation"/>
        </div>

        <div class="grid row-15">
            <h3>Broker details</h3>
            <div class="grid gap-5">
                <InputDropDownList name="Broker" :error="errors.broker" @search="searchBrokers" :loading="isLoading(['AddLoad-SearchBrokers'])" dynamic-search v-model="item.broker" placeholder="Select broker" selectAttribute="_id" :showAttribute="(broker) => { return broker.name + ' - ' + broker.mc }" :options="brokers"/>
                <div>Can't find broker? <a href="" @click.prevent="addBroker">Add broker</a></div>   
            </div>
            <Input name="Pro #" v-model="item.brokerLoadNumber" :error="errors.brokerLoadNumber" placeholder="Enter load number" description="Pro # provided by the broker. It helps locate loads faster."/>
            <Input name="Contact name" v-model="item.brokerContactName" :error="errors.brokerContactName" placeholder="Enter contact name"/>
            <div class="grid cols-2 gap-15">
                <Input name="Phone" v-model="item.brokerContactPhone" :error="errors.brokerContactPhone" placeholder="Enter phone"/>
                <Input name="Email address" v-model="item.brokerContactEmail" :error="errors.brokerContactEmail" placeholder="Enter email address"/>
            </div>
        </div>
        

        <div v-if="isLoading(['AddLoad-GetCarriers', 'AddLoad-GetCarrierChargeTypes', 'AddLoad-GetCarrierDrivers', 'AddLoad-HandleAdding'])" class="spinner-flex"><Spinner/></div>
    </div>
</template>

<script>
import LoadStops from './LoadStops.vue'
import BrokersJS from '../../../mixins/Brokers'

import CitySearch from './CitySearch.vue'

    export default {
        mixins: [BrokersJS],
        components: {
            CitySearch
        },
        props: ['modal'],
        data() {
            return {
                item: {},
                errors: {},
                carriers: [],
                chargeTypes: [],
                drivers: [],
                brokers: []
            }
        },
        watch: {
            "item.carrier"() {
                this.getCarrierChargeTypes();
                this.getCarrierDrivers();
                this.item.driver = null;
                this.item.carrierChargeType = null;
            }
        },
        methods: {
            addBroker() {
                this.brokers_add((broker) => {
                    if(broker._id) {
                        this.item.broker = broker._id;
                        this.brokers.unshift(broker);
                    }
                });
            },
            selectLoadStops() {
                let stops = this.item.stops || [];
                stops = JSON.parse(JSON.stringify(stops));

                let modal = this.$ShowModal({
                    title: 'Enter Load Stops',
                    component: LoadStops,
                    props: {
                        stops: stops,
                        errors: {}
                    }
                });

                modal.onConfirm(async (props) => {
                    modal.processing(true);
                    await this.ajax('AddLoad-ValidateLoadStops', {
                        url: '/dispatch/loads/TestStops',
                        method: 'POST',
                        data: props.stops
                    }, (err, body) => {
                        if(err) {
                            this.$ShowAlert(body.message || 'Unfortunately, something went wrong.');
                            return;
                        }
                        this.item.stops = body;
                        modal.close();
                    });
                    modal.processing(false);
                });

            },
            searchBrokers(search) {
                this.ajax('AddLoad-SearchBrokers', {
                    url: '/dispatch/brokers/search',
                    method: 'POST',
                    data: {
                        search,
                        forceShow: this.item.broker ? this.item.broker : null
                    }
                }, (err, body) => {
                    if(!err) this.brokers = body;
                });
            },
            getListOfCarriers() {
                this.ajax('AddLoad-GetCarriers', {
                    url: '/dispatch/carriers/all',
                    method: 'GET',
                }, (err, body) => {
                    if(!err) this.carriers = body;
                });
            },
            getCarrierChargeTypes() {
                this.chargeTypes = [];
                if(!this.item.carrier) return;
                this.ajax('AddLoad-GetCarrierChargeTypes', {
                    url: `/dispatch/carriers/chargeTypes/${this.item.carrier}`,
                    method: 'GET',
                }, (err, body) => {
                    if(!err) this.chargeTypes = body;
                });
            },
            getCarrierDrivers() {
                this.drivers = [];
                if(!this.item.carrier) return;
                this.ajax('AddLoad-GetCarrierDrivers', {
                    url: `/dispatch/carrier/drivers/${this.item.carrier}/all`,
                    method: 'GET',
                }, (err, body) => {
                    if(!err) this.drivers = body;
                });
            },
            HandleAddLoad() {
                this.errors = {};
                this.ajax('AddLoad-HandleAdding', {
                    url: `/dispatch/loads`,
                    method: 'POST',
                    data: this.item
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else this.$ShowAlert(body.message || 'Unfortunately, something went wrong.');
                        return;
                    }
                    this.modal.close();
                });
            }
        },
        mounted() {
            this.getListOfCarriers();
            this.searchBrokers();
            this.modal.onConfirm(() => {
                this.HandleAddLoad();
            });
        }
    }
</script>

<style lang="scss" scoped>
.add-load-wrapper {
    padding: 20px;
    position: relative;
}


.spinner-flex {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background: rgba(255,255,255,0.5);
    z-index: 3;
}

.stops-list {
    .stop-item {
        color: $textShade;
        padding: 10px 0 0 20px;
        position: relative;
        display: grid;
        row-gap: 2px;
        &::before {
            content: '';
            position: absolute;
            top: 10px;
            left: 0;
            width: 7px;
            height: 8px;
            background: $themeColor2;
            border: 1px solid $themeColor2;
            box-sizing: border-box;
            transform: translate(5px, 5px);
        }
        &::after {
            content: '';
            position: absolute;
            top: 10px;
            left: 0;
            bottom: 8px;
            width: 1px;
            background: $themeColor2;
            transform: translate(8px, 18px);
        }
        &:last-child {
            &::after {
                content: none;
            }
        }
        &.pick {
            &::before {
                background: transparent;
            }
        }
        .stop-type {
            height: 20px;
            display: flex;
            align-items: center;
            font-size: 12px;
            text-transform: uppercase;
        }
        .stop-city {
            color: $themeColor2;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 14px;
            font-weight: 500;
        }
    }
}
</style>